@import url("https://fonts.googleapis.com/css?family=Khand");
@import url("https://fonts.googleapis.com/css?family=Oswald");

.menu2 html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.menu2 {
  background-image: url("https://i.postimg.cc/v8V8WRFS/fundo.png");
  color: #f2f2f2;
  padding: 0;
  margin: 0;
  font-family: "Khand", sans-serif;
  font-weight: lighter;
  margin-bottom: 4px;
}

.menu2 a {
  text-decoration: none;
}

.menu2 .container {
  width: 80%;
  margin: auto;
}

.menu2 .home {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.menu2 .img-fluid {
  max-width: 100%;
  height: auto;
}

.menu2 .top-nav {
  position: relative;
  text-align: center;
  margin: 0;
  font-size: 2em;
  display: block;
  margin: 0 auto;
}

.menu2 .top-nav a {
  color: #f2f2f2;
  font-family: "Khand", sans-serif;
  display: block;
}

.menu2 .top-nav a img {
  margin-top: -15px;
}

.menu2 .aside-float {
  text-align: center;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-image: url("https://i.postimg.cc/v8V8WRFS/fundo.png");
  padding: 5px;
  z-index: 100;
}

.menu2 .nav-side {
  position: static;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.menu2 .nav-side.fixed {
  position: fixed !important;
  position: fixed !important;
  left: 0;
  background-image: url("https://i.postimg.cc/v8V8WRFS/fundo.png");
  align-items: center;
}
.menu2 .nav-side a {
  color: #f2f2f2;
  font-size: 1.2em;
  display: block;
  padding: 10px;
}

.menu2 .nav-side img {
  vertical-align: middle;
  padding-left: 10px;
}

.menu2 .items {
  width: 100%;
  padding-top: 25px;
}

.menu2 .menu-items {
  padding: 0;
  list-style: none;
  width: 100%;
}

.menu2 .item {
  display: flex;
  justify-content: space-between;
  break-inside: avoid;
  border-bottom: #f2f2f2 2px dotted;
  font-size: 1.4em;
}
.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.menu2 .content-menu {
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.menu2 .content-side {
  display: block;
  width: 100%;
}

.menu2 .header1 {
  position: relative;
  text-align: center;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; if scroll animation */
}
.menu2 .text {
  font-size: 3rem;
  margin-top: 0.5em;
  text-align: center;
  letter-spacing: 4px;
  font-weight: 400;
}

.menu2 .h1 {
  font-size: 5em;
  text-align: center;
  border-top: #f2f2f2 2px solid;
  border-bottom: #f2f2f2 2px solid;
}

.menu2 section h1 {
  color: #f2f2f2;
  font-size: 4em;
  margin: 0;
  text-align: center;
  background-color: rgba(37, 28, 15, 0.534);
}

.menu2 footer {
  padding-top: 60px;
  padding-bottom: 10px;
  text-align: center;
}

.menu2 footer p {
  margin: 0;
  font-size: 1.2em;
}

.menu2 footer img {
  width: 50px;
  height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.menu2 .ingredients {
  font-size: 12px;
}
.menu2 .price {
  font-size: 20px;
}
.menu2 .banner {
  width: 300px;
  margin-bottom: 20px;
  display: inline-block;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.arrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background-image: url('./cart.png');
  background-size: contain;
  z-index: 1;
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}