.menu1 .menu {
  display: none;
}
.menu1 .menu--is-visible {
  display: grid;
}
@media (min-width: 768px) {
  .menu1 .menu {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
  }
}

.menu1 .item__header {
  display: flex;
  align-items: baseline;
}
.menu1 .item__title {
  font: 35px var(--font);
  color: var(--themecolor);
  letter-spacing: 2px;
  margin: 0;
}
.menu1 .item__title.heineken {
  font: 35px var(--font);
  color: var(--themecolor);
  letter-spacing: 2px;
  margin: 0;
  text-align: left;
  animation-name: zoomInOut;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
    color: var(--themecolor);
  }
  50% {
    transform: scale(1.2);
    color:var(--fontcolor);
  }
  100% {
    transform: scale(1);
    color: var(--themecolor);
  }
}
.menu1 .item__dots {
  flex: 1;
  border-bottom: 1px dashed #aaa;
  margin: 0 15px;
}
.menu1 .item__price {
  color: var(--themecolor);
  font: 31px var(--font);
}
.menu1 .item__description {
  margin-bottom: 40px;
  color: var(--fontcolor);
}
.menu1 .item {
  order: 2;
}
.menu1 .item.heineken {
  order: 1;
}
.menu1 .buttons-container {
  margin-bottom: 35px;
  overflow: auto;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}

@media (min-width: 768px) {
  .menu1 .buttons-container {
    margin-bottom: 60px;
  }
}

.menu1 .button,
.menu1 .button:hover,
.menu1 .button:active,
.menu1 .button:focus {
  margin: 5px 15px;
  padding: 10px 20px;
  border: 1px solid var(--themecolor);
  border-radius: 3px;
  color: var(--fontcolor);
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
}

.menu1 .button--is-active {
  background: var(--themecolor);
  border-radius: 3px;
  transition: 0.24s;
}
.menu1 .button2,
.menu1 .button2:hover,
.menu1 .button2:active,
.menu1 .button2:focus {
  margin: 5px 15px;
  padding: 10px 20px;
  border: 1px solid var(--themecolor);
  border-radius: 3px;
  color: var(--fontcolor);
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
}

.menu1 .button2--is-active {
  background: var(--themecolor);
  border-radius: 3px;
  transition: 0.24s;
}

.menu1 * {
  box-sizing: border-box;
}

.menu1 {
  margin: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("bg.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #fff;
}

.menu1 .wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.menu1 h1 {
  font: 50px var(--font);
}
.menu1 h2 {
  display: inline-block;
  border-bottom: 4px solid var(--themecolor) !important;
  margin-top: 0;
  font: 50px var(--font);
}
.menu1 .banner img {
  width: 300px;
  margin-bottom: 20px;
}
.menu1 .footer {
  margin-top: 40px;
}
.menu1 .social-media nav a img {
  width: 30px;
}
/* .input-group input {
  background-color: white !important;
} */

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.arrow {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;

  background-size: contain;
  z-index: 1;
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.menu1 .btn-addition {
  font-size: 12px;
  background-color: black;
  border: none;
  color: #fff;
  display: block;
  padding: 4px 20px 4px 20px;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.select-language {
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
  width: 200px;
}

.select-language option {
  padding: 5px;
}

.flag-icon {
  margin-right: 5px;
}