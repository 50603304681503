.outer-container {
  background-color: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  margin: 50px auto;
  width: 400px;
  height: 700px;
  box-sizing: border-box;
  border-radius: 0.5em;
  overflow-y: hidden;
  overflow-x: hidden;
}
.outer-container:hover {
  overflow-y: scroll;
}
.row .lang-switcher {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ui-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.brown {
  background-color: #927c78;
}

.silver {
  background-color: #dbdfd4;
}

.orange {
  background-color: #f3bb72;
}

.pink {
  background-color: #ff85a7;
}

.blue {
  background-color: #7beced;
}

.dark-blue {
  background-color: #9cb4f0;
}

.green {
  background-color: #b1dc76;
}

.purple {
  background-color: #bd84d6;
}

p {
  transition: 300ms ease-out;
  margin: 0;
}

.ui-item {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.3em;
  height: 150px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5em;
  transition: 300ms ease-out;
}
.ui-item:hover {
  cursor: pointer;
}
.ui-item:hover p {
  font-size: 1.5em;
}
.ui-item:hover .food-icon {
  transform: rotate(-35deg);
}

.food-icon {
  font-size: 4em;
  transition: 300ms ease-out;
}

.ion-ios-arrow-forward {
  color: white;
  font-size: 1.5em;
  transition: 100ms ease-out;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
}

.custom-app-bar {
  padding-right: 0 !important;
}
