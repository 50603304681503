@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");
.satisfaction body {
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 18px;
  background: #eeeeee;
  color: #1f2024;
  background-color: aliceblue !important;
}
.satisfaction {
  min-height: 100vh; /* Set the minimum height to the full viewport height */
  display: flex; /* Use flexbox to center the content vertically */
  flex-direction: column; /* Arrange the content in a column */
}
.satisfaction input[type="radio"] {
  display: none;
}
.statisfaction ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.satisfaction .rating-emojis {
  max-width: 400px;
  margin: 80px auto;
  background: #fff;
  padding: 3em;
  border-radius: 3px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.satisfaction .rating__title {
  text-align: center;
  font-weight: 700;
  display: block;
}
.satisfaction .rating__list {
  max-width: 300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}
.satisfaction .rating__input {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:after {
  display: none;
}
[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:after  {
  display: none;
}
[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:before {
  display: none;
}
[type="radio"]:not(:checked) + label:before, [type="radio"]:not(:checked) + label:before  {
  display: none;
}

.theme-danger [type=radio]:checked + label:after {
  display: none;
}
.satisfaction .rating__label {
  padding: 5px 3px;
  font-size: 26px;
  filter: grayscale(1);
  opacity: 0.7;
  cursor: pointer;
  padding-left: 0px !important ;
}
.satisfaction .rating__label.active {
  filter: grayscale(0);
  opacity: 1;
  transition: all 100ms ease;
  font-weight: bold;
}
.satisfaction .rating__label:hover {
  filter: grayscale(0.84);
  transform: scale(1.1);
  transition: 100ms ease;
}

.satisfaction .feedback {
  width: 100%;
  display: none;
}
.satisfaction .feedback textarea,
.satisfaction .feedback input {
  max-width: 300px;
  width: 100%;
  display: block;
  margin: 0.5em auto;
  padding: 0.5em;
  font-family: inherit;
  border: 1px solid #d1d2d7;
  border-radius: 3px;
}
.satisfaction .feedback textarea:focus,
.satisfaction .feedback textarea:active,
.satisfaction .feedback input:focus,
.satisfaction .feedback input:active {
  border-color: #c80000;
  box-shadow: 0px 0px 0px 3px rgba(200, 0, 0, 0.2);
  transition: 100ms;
  outline: 0;
}
.satisfaction .feedback textarea {
  height: 100px;
}
.satisfaction .feedback button {
  margin: 1em auto;
  display: table;
  text-align: center;
}
.satisfaction .feedback button:focus,
.satisfaction .feedback button:active {
  outline: 0;
}

.satisfaction .cb911_logo {
  width: 250px;
  position: absolute;
  top: 1em;
  left: 50%;
  margin-left: -125px;
}

.satisfaction button {
  border-radius: 3px;
  font-family: "Source Sans Pro", Arial, sans-serif;
  border: 0;
  padding: 9px 15px;
  font-size: 15px;
  color: white;
  background-color: #c80000;
  text-shadow: 0px 1px 1px #620000;
  cursor: pointer;
}

.satisfaction button.not-disabled {
  color: white;
  background-color: #c80000;
  text-shadow: 0px 1px 1px #620000;
  cursor: pointer;
}
.satisfaction button.not-disabled:hover {
  background-color: #950000;
  transition: 100ms;
}
